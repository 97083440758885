import { CardMedia } from '@mui/material';

const LogoName = 'vedartha-01.svg';

const logoWidth = 'unset';

export const Logo1 = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt={`Logo`}
      sx={{ width: logoWidth, m: 3 }}
    />
  );
};

export const LogoEditApplication = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt={`Logo`}
      sx={{ width: logoWidth, m: 3, mb: 2 }}
    />
  );
};

export const LogoSideBar = (): JSX.Element => {
  return (
    <CardMedia component="img" src={`/images/${LogoName}`} alt={`Logo`} sx={{ width: logoWidth }} />
  );
};
