import { Box, Grid, Typography, Theme } from '@mui/material';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Item, ItemDetails, Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SxProps } from '@mui/system';
import { applicantStatusMasters, isNaturalParent } from '../../utils/constant';
import { isFatherMother, isOnboardingTypeMinor } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';

export const KeyValue = ({
  title,
  description,
  amount,
  sx = {},
  descriptionSx = {},
  full = false,
}: {
  title: string | undefined;
  description?: string[] | string | number | boolean | null;
  amount?: number | null | undefined | string;
  sx?: SxProps<Theme>;
  descriptionSx?: SxProps<Theme>;
  full?: boolean;
}): JSX.Element => {
  return (
    <Grid item xs={12} sm={full ? 12 : 6}>
      <Item sx={sx}>{title}</Item>
      <ItemDetails sx={descriptionSx}>
        {' '}
        {description || description === 0 ? description : '-'}
      </ItemDetails>
      {!!amount && amount !== 0 && <Item sx={{ pt: 0 }}> {amount}</Item>}
    </Grid>
  );
};

export default function ContributorDetails({
  applicants = [],
  modeOfHolding,
}: {
  applicants: Partial<Applicant>[];
  modeOfHolding: string;
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'text.secondary',
                }}>
                Mode of Holding:{' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                    textTransform: 'capitalize',
                  }}>
                  {modeOfHolding || 'Single'}
                </Typography>
              </Typography>
            </Grid>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {isOnboardingTypeMinor(applicant?.onboardingType || '')
                      ? 'Contributor Details of Guardian'
                      : title}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name'} description={applicant.name} />
                      <KeyValue title={'Gender'} description={applicant.gender} />
                      <KeyValue
                        title={'Date Of Birth'}
                        description={formatShortDate(applicant.dateOfBirth)}
                      />
                      <KeyValue
                        title={'Father/Spouse Name'}
                        description={applicant.fatherOrSpouseName}
                      />
                      <KeyValue title={'Mother Name'} description={applicant.motherName} />
                      <KeyValue title={'PAN'} description={applicant.panNo} />
                      <KeyValue
                        title={'Status'}
                        description={
                          applicant.statusSubType
                            ? applicantStatusMasters[applicant.statusSubType]
                            : applicant.status
                            ? applicantStatusMasters[applicant.status]
                            : applicant.status
                        }
                      />
                      <KeyValue
                        title={'Nationality of Applicant'}
                        description={applicant.nationality}
                      />
                      <KeyValue
                        title={'CVL KRA Verified'}
                        description={applicant.kraVerified}
                        descriptionSx={{
                          textTransform: 'capitalize',
                        }}
                      />
                      <KeyValue
                        title={`AML Verified`}
                        description={applicant.amlCheck ? 'Yes' : 'No'}
                      />
                      {index !== 0 && (
                        <KeyValue
                          title={'RelationShip With First Applicant'}
                          description={applicant.relationShipWithFirstApplicant}
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                  {isOnboardingTypeMinor(applicant?.onboardingType || '') && (
                    <>
                      <SubHeading
                        sx={{
                          color: 'common.black',
                          letterSpacing: 0.5 + 'px',
                          padding: { xs: '10px 10px', sm: '10px 30px' },
                        }}>
                        Contributor Details of Minor Applicant
                      </SubHeading>
                      <Gridstyles>
                        <Grid
                          item
                          container
                          pb={5}
                          rowSpacing={6}
                          columnSpacing={13}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                          <KeyValue title={'PAN'} description={applicant.minorPan} />
                          <KeyValue
                            title={'Date of Birth'}
                            description={formatShortDate(applicant.minorDOB)}
                          />
                          <KeyValue title={'Full Name'} description={applicant.minorName} />
                          <KeyValue title={'Father Name'} description={applicant.minorFatherName} />
                          <KeyValue title={'Gender'} description={applicant.minorGender} />
                          <KeyValue title={'Email ID'} description={applicant.minorEmail} />
                          <KeyValue
                            title={'Mobile Number'}
                            description={
                              applicant.minorCountryCode && applicant.minorMobile
                                ? applicant.minorCountryCode + '-' + applicant.minorMobile
                                : applicant.minorMobile
                            }
                          />
                          <KeyValue
                            title={'Relationship with Guardian'}
                            description={applicant.guardianRelationship}
                          />
                          {isFatherMother(applicant.guardianRelationship || '') && (
                            <KeyValue
                              title={'Natural Parents'}
                              description={isNaturalParent[applicant.naturalParent || '']}
                            />
                          )}
                        </Grid>
                      </Gridstyles>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
