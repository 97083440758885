import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { accessType, ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { fundAccess, getPlanDetail } from '../../redux-store/actions/funds';
import { Location } from 'history';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  currencyConversion,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
} from '../../utils/utilityFunctions';
import {
  CurrencyMaster,
  defaultCurrency,
  ENABLE_GROUP_INVESTMENT,
  FEE_TYPE_MASTER,
  YES_NO_MASTER,
} from '../../utils/constant';
import { styles } from '../../Styles/styles';
import { DistributorListType } from '../../redux-store/types/api-types';
import ExitLoad from '../ApplicationDetails/exitload';

export const SuffixForPlanAndDistributor = (
  distributorAndPlanData: DistributorListType[] | FundProps[] | ClassPlanProps[]
): string => {
  if (distributorAndPlanData.length > 0) {
    return distributorAndPlanData.length === 1 ? '' : 's';
  }
  return '';
};

export default function PlanDetails({
  location,
}: {
  location: Location<{ PlanId: string; fundId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { PlanId, fundId } = location.state || {};
  const [planDetails, setPlanDetails] = useState<ClassPlanProps>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (PlanId) {
          const res = (await dispatch(getPlanDetail(PlanId))) as unknown as ClassPlanProps;
          setPlanDetails(res);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
              // pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId, PlanId: PlanId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Plan Details'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>

          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {planDetails && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Plan Details'}
                </SubHeading>
              </Box>

              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Plan Code'} description={planDetails?.planCode} />
                      <KeyValue
                        title={'Plan Description'}
                        description={planDetails?.planDescription}
                      />
                      <KeyValue title={'Scheme Nature'} description={planDetails?.schemeNature} />
                      {/* <KeyValue
                        title={'Registration Number'}
                        description={planDetails?.registrationNumber}
                      /> */}
                      <KeyValue
                        title={'Scheme Category'}
                        description={planDetails?.schemeCategory}
                      />
                      <KeyValue title={'Country'} description={planDetails?.country} />
                      <KeyValue
                        title={'Currency'}
                        description={
                          planDetails?.currency
                            ? CurrencyMaster[
                                planDetails?.currency?.toLowerCase() || defaultCurrency
                              ]?.currency
                            : planDetails?.currency
                        }
                      />
                      <KeyValue
                        title={'Scheme Start Date'}
                        description={formatShortDate(planDetails?.schemeStartDate)}
                      />
                      <KeyValue
                        title={'Lock in Period(In years)'}
                        description={planDetails?.lockInPeriod}
                      />
                      <KeyValue
                        title={'Lock in Extension(In years)'}
                        description={planDetails?.lockInExtension}
                      />
                      <KeyValue title={'Face Value'} description={planDetails?.faceValue} />
                      <KeyValue title={'Fund Address 1'} description={planDetails?.fundAddress1} />
                      <KeyValue title={'Fund Address 2'} description={planDetails?.fundAddress2} />
                      <KeyValue title={'Fund Address 3'} description={planDetails?.fundAddress3} />
                      <KeyValue
                        title={'Setup Fee Applicable'}
                        description={YES_NO_MASTER[planDetails?.setupFeeApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.setupFeeApplicable) && (
                        <>
                          <KeyValue
                            title={'Setup Fee Type'}
                            description={FEE_TYPE_MASTER[planDetails?.setupFeeType || '']}
                          />
                          <KeyValue title={'Setup Fee %'} description={planDetails?.setupFee} />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.setupFeeType,
                        planDetails?.setupFeeApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Setup Fee %'}
                            description={planDetails?.minSetupFee}
                          />
                          <KeyValue
                            title={'Maximum Setup Fee %'}
                            description={planDetails?.maxSetupFee}
                          />
                        </>
                      )}
                      <KeyValue title={'Custodian'} description={planDetails?.custodian} />
                      <KeyValue
                        title={'Minimum Commitment Amount'}
                        description={planDetails?.minCommitmentAmount}
                        amount={currencyConversion(
                          planDetails?.minCommitmentAmount,
                          planDetails?.currency
                        )}
                      />
                      <KeyValue
                        title={'Maximum Commitment Amount'}
                        description={planDetails?.maxCommitmentAmount}
                        amount={currencyConversion(
                          planDetails?.maxCommitmentAmount,
                          planDetails?.currency
                        )}
                      />
                      {ENABLE_GROUP_INVESTMENT && (
                        <KeyValue
                          title={'Group Investment Commitment Amount'}
                          description={planDetails?.minInvestmentAmt}
                          amount={currencyConversion(
                            planDetails?.minInvestmentAmt,
                            planDetails?.currency
                          )}
                        />
                      )}
                      <KeyValue
                        title={'Minimum Contribution Percentage'}
                        description={planDetails?.minContributionPercentage}
                      />
                      <KeyValue
                        title={'Investment Manager Name(s)'}
                        description={planDetails?.fundmanagerDetails?.fundmanagers
                          ?.map((fundManager) => fundManager.name)
                          .join(', ')}
                      />
                      <KeyValue
                        title={'No. of Investment Manager(s) to esign'}
                        description={planDetails?.requiredFundManagers}
                      />
                      <KeyValue
                        title={'Trustee Name(s)'}
                        description={planDetails?.trusteeDetails?.trustees
                          ?.map((trustee) => trustee.name)
                          .join(', ')}
                      />
                      <KeyValue
                        title={'No. of Trustee(s) to esign'}
                        description={planDetails?.requiredTrustees}
                      />
                      <KeyValue title={'T&C Link'} description={planDetails?.tcLink} />

                      <KeyValue
                        title={'Accredited Investor Applicable'}
                        description={YES_NO_MASTER[planDetails?.accreditedInvestorApplicable]}
                      />
                      {isAccreditedInvestorApplicable(
                        planDetails?.accreditedInvestorApplicable
                      ) && (
                        <KeyValue
                          title={'Accredited Minimum Commitment Amount'}
                          description={planDetails?.accreditedMinCommitmentAmount}
                        />
                      )}
                      <KeyValue
                        title={'Management Fee Applicable'}
                        description={YES_NO_MASTER[planDetails?.managementFeeApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.managementFeeApplicable) && (
                        <>
                          <KeyValue
                            title={'Management Fee Type'}
                            description={FEE_TYPE_MASTER[planDetails?.managementFeeType || '']}
                          />
                          <KeyValue
                            title={'Management Fee %'}
                            description={planDetails?.defaultManagementFee}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.managementFeeType,
                        planDetails?.managementFeeApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Management Fee %'}
                            description={planDetails?.minManagementFee}
                          />
                          <KeyValue
                            title={'Maximum Management Fee %'}
                            description={planDetails?.maxManagementFee}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Hurdle Rate Applicable'}
                        description={YES_NO_MASTER[planDetails?.hurdleRateApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.hurdleRateApplicable) && (
                        <>
                          <KeyValue
                            title={'Hurdle Rate Type'}
                            description={FEE_TYPE_MASTER[planDetails?.hurdleRateType || '']}
                          />
                          <KeyValue
                            title={'Hurdle Rate %'}
                            description={planDetails?.defaultHurdleRate}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.hurdleRateType,
                        planDetails?.hurdleRateApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Hurdle Rate %'}
                            description={planDetails?.minHurdleRate}
                          />
                          <KeyValue
                            title={'Maximum Hurdle Rate %'}
                            description={planDetails?.maxHurdleRate}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Performance Fee Applicable'}
                        description={YES_NO_MASTER[planDetails?.performanceFeeApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.performanceFeeApplicable) && (
                        <>
                          <KeyValue
                            title={'Performance Fee Type'}
                            description={FEE_TYPE_MASTER[planDetails?.performanceFeeType || '']}
                          />
                          <KeyValue
                            title={'Performance Fee %'}
                            description={planDetails?.defaultPerformanceFee}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.performanceFeeType,
                        planDetails?.performanceFeeApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Performance Fee %'}
                            description={planDetails?.minPerformanceFee}
                          />
                          <KeyValue
                            title={'Maximum Performance Fee %'}
                            description={planDetails?.maxPerformanceFee}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Exit Charge Applicable'}
                        description={YES_NO_MASTER[planDetails?.exitChargeApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.exitChargeApplicable) && (
                        <>
                          <KeyValue
                            title={'Exit Charge Type'}
                            description={FEE_TYPE_MASTER[planDetails?.exitChargeType || '']}
                          />
                          <KeyValue
                            title={'Exit Charge %'}
                            description={planDetails?.defaultExitCharge}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.exitChargeType,
                        planDetails?.exitChargeApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Exit Charge %'}
                            description={planDetails?.minExitCharge}
                          />
                          <KeyValue
                            title={'Maximum Exit Charge %'}
                            description={planDetails?.maxExitCharge}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Placement Fee Applicable'}
                        description={YES_NO_MASTER[planDetails?.placementFeeApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.placementFeeApplicable) && (
                        <>
                          <KeyValue
                            title={'Placement Fee Type'}
                            description={FEE_TYPE_MASTER[planDetails?.placementFeeType || '']}
                          />
                          <KeyValue
                            title={'Placement Fee %'}
                            description={planDetails?.defaultPlacementFee}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.placementFeeType,
                        planDetails?.placementFeeApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Placement Fee %'}
                            description={planDetails?.minPlacementFee}
                          />
                          <KeyValue
                            title={'Maximum Placement Fee %'}
                            description={planDetails?.maxPlacementFee}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Operating Expense Applicable'}
                        description={YES_NO_MASTER[planDetails?.operatingExpenseApplicable || '']}
                      />
                      {isFieldApplicable(planDetails?.operatingExpenseApplicable) && (
                        <>
                          <KeyValue
                            title={'Operating Expense Type'}
                            description={FEE_TYPE_MASTER[planDetails?.operatingExpenseType || '']}
                          />
                          <KeyValue
                            title={'Operating Expense %'}
                            description={planDetails?.defaultOperatingExpenses}
                          />
                        </>
                      )}
                      {isFieldVariable(
                        planDetails?.operatingExpenseType,
                        planDetails?.operatingExpenseApplicable
                      ) && (
                        <>
                          <KeyValue
                            title={'Minimum Operating Expense %'}
                            description={planDetails?.minOperatingExpense}
                          />
                          <KeyValue
                            title={'Maximum Operating Expense %'}
                            description={planDetails?.maxOperatingExpense}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Direct'}
                        description={planDetails?.isApplicableForDirect ? 'Yes' : 'No'}
                      />
                      <KeyValue
                        title={'Exit Load Applicable'}
                        description={YES_NO_MASTER[planDetails.exitLoadApplicable]}
                      />
                      {isFieldApplicable(planDetails.exitLoadApplicable) && (
                        <Grid item xs={6} pb={2}>
                          <ExitLoad exitLoad={planDetails?.exitLoad || ''} />
                        </Grid>
                        // <KeyValue title={'Exit Charge'} description={planDetails?.exitCharge} />
                      )}
                      <Grid item xs={12}>
                        <Item>{`Applicable Distributor${
                          (planDetails?.distributorDetails &&
                            Object.keys(planDetails?.distributorDetails).length > 0 &&
                            SuffixForPlanAndDistributor(
                              planDetails?.distributorDetails?.distributors
                            )) ||
                          ''
                        }`}</Item>
                        {planDetails?.distributorDetails?.distributors.length > 0 ? (
                          <Box
                            sx={{
                              border: 0.5,
                              // borderColor: 'rgba(41, 49, 57, 0.7)',
                              borderColor: 'primary.main',
                              p: 3,
                              mb: 3,
                              mt: 2,
                              borderRadius: 0.5,
                            }}>
                            {planDetails?.distributorDetails.distributors.map((distributor) => {
                              return (
                                <React.Fragment key={distributor.id}>
                                  <Chip
                                    label={`${distributor.arnCode || 'N/A'} - ${
                                      distributor.name
                                    } (${distributor.type})`}
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: 'rgba(41, 49, 57, 0.7)',
                                      // p: 2,
                                      m: 0.8,
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        ) : (
                          <ItemDetails>-</ItemDetails>
                        )}
                      </Grid>
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
